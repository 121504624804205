
// import { AGENT_SERVICE_URL,DEFAULT_AGENTTOKEN } from './Config';

// import axios from 'axios';

// import store from '../store';

// // URL obfuscation function
// function obfuscateURL(url) {
//   // Obfuscation algorithm ka istemal karein, jaise ki reverse karke
//   return url.split('').reverse().join('');
// }



// const getTokenFromStore = () => {
//   const { agent } = store.getState();
  
//   return agent.agent_isToken; // Assuming 'isToken' is the token state in your 'auth' slice
// };


// const axiosInstance = axios.create({
//   baseURL: AGENT_SERVICE_URL,
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded',
//     'x-agent-apay': DEFAULT_AGENTTOKEN,
//   },
// });


// axiosInstance.interceptors.response.use(
//   (response) => {
  
//     if (response.data.message === 'This token is already expired!!' || 
//         response.data.message === 'Forbidden error! You must set token type' || 
//         response.data.message === 'Forbidden error! You must set token' || 
//         response.data.message === 'Forbidden error!' || 
//         response.data.message === 'Invalid token' || 
//         response.data.message === 'Unauthorized Token!') {
//       // Handle token expiration or forbidden error here
//       // For example, clear localStorage or redirect to login page
//       // localStorage.clear();
//       // window.location.href = '/admin/login';
//     }
//     return response;
//   },
//   (error) => {
//     // Handle network errors or other API-related errors here
//     // You may want to throw a custom error or return a custom error object
//     // depending on how you want to handle these cases
//     return Promise.reject(error);
//   }
// );


// const Agent_LoginOTPService = async (requestData) => {
//   try {
//     const response = await axiosInstance.post(`/agent_login`, requestData);
//     const authToken = response.headers['x-agent-apay'];
//     return {authToken, data: response.data };
//   } catch (error) {
//     return error;
//   }
// }

// const LogoutandOTPService = async (requestData) => {
//   try {
//     const response = await axiosInstance.post(`/agentlogoutandcontinue`, requestData);
//     const authToken = response.headers['x-agent-apay'];
//     return {authToken, data: response.data };
//   } catch (error) {
//     return error;
//   }
// }

// const LogoutVerifyService = async (requestData) => {
//   try {
//     const response = await axiosInstance.post(`/AgentLogoutVerifyOtp`, requestData);
//     const authToken = response.headers['x-agent-apay'];
//     return {authToken, data: response.data };
//   } catch (error) {
//     return error;
//   }
// }


// const ForgotPassword = async (requestData) => {
//   try {
//     const response = await axiosInstance.post(`/agentforgotPassword`, requestData);
//     const authToken = response.headers['x-agent-apay'];
//     return {authToken, data: response.data };
//   } catch (error) {
//     return error;
//   }
// }

// const VerifyForgotPassword = async (requestData) => {
//   try {
//     const response = await axiosInstance.post(`/agentChangePssword`, requestData);
//     const authToken = response.headers['x-agent-apay'];
//     return {authToken, data: response.data };
//   } catch (error) {
//     return error;
//   }
// }


// const LoginVerifyAgentService = async (requestData) => {
//   try {
//     const response = await axiosInstance.post(`/agent_loginVerify`, requestData);
//     const authToken = response.headers['x-agent-apay'];
 
//     return {authToken, data: response.data };
//   } catch (error) {
//     return error;
//   }
// }



// const kyc_updatebyself = async (requestData) => {

//   const response = await axiosInstance.post(`/kyc_updatebyself`, requestData, {
//     headers: {
//         'Content-Type': 'multipart/form-data',
//         'x-agent-apay':getTokenFromStore(),
//       }
   
// });
//   return response;
// }

// const Check_IFSC = async (requestData) => {

//   const response = await axiosInstance.post(`/check_ifsc`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//     }
// });
//   return response;
// }

// const Check_Account_Verify = async (requestData) => {

//   const response = await axiosInstance.post(`/check_accountverify`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//     }
// });
//   return response;
// }

// const Add_New_Beneficiary = async (requestData) => {

//   const response = await axiosInstance.post(`/add_beneficiary`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
   
// });
//   return response;
// }

// const List_Beneficiary = async (requestData) => {
//   const response = await axiosInstance.post(`/beneficiary_list`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }


// const Profile_agent_ = async () => {
//   const response = await axiosInstance.get(`/agent_profile`, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const Profile_agentpost = async (requestData) => {
//   const response = await axiosInstance.post(`/agent_profilepost`,requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const kyc_AadharFrontbyself = async (requestData) => {

//   const response = await axiosInstance.post(`/upload_aadharfrontphoto`, requestData, {
//     headers: {
//         'Content-Type': 'multipart/form-data',
//         'x-agent-apay':getTokenFromStore(),
//       }
   
// });
//   return response;
// }

// const kyc_AadharBackbyself = async (requestData) => {

//   const response = await axiosInstance.post(`/upload_aadharbacktphoto`, requestData, {
//     headers: {
//         'Content-Type': 'multipart/form-data',
//         'x-agent-apay':getTokenFromStore(),
//       }
   
// });
//   return response;
// }

// const kyc_PANbyself = async (requestData) => {

//   const response = await axiosInstance.post(`/upload_panphoto`, requestData, {
//     headers: {
//         'Content-Type': 'multipart/form-data',
//         'x-agent-apay':getTokenFromStore(),
//       }
   
// });
//   return response;
// }
// const AgentPhoto = async (requestData) => {

//   const response = await axiosInstance.post(`/upload_Agentphoto`, requestData, {
//     headers: {
//         'Content-Type': 'multipart/form-data',
//         'x-agent-apay':getTokenFromStore(),
//       }
   
// });
//   return response;
// }


// const GoDashboard = async () => {
//   const response = await axiosInstance.get(`/go_dashboard`, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const Check_UPIVerify = async (requestData) => {

//   const response = await axiosInstance.post(`/check_upiverify`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//     }
// });
//   return response;
// }

// const Added_UPIVerify = async (requestData) => {

//   const response = await axiosInstance.post(`/added_upi`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//     }
// });
//   return response;
// }

// const List_UPI = async (requestData) => {
//   const response = await axiosInstance.post(`/upi_list`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const Choosebeneficiary = async (requestData) => {
//   const response = await axiosInstance.post(`/selectedbeneficiary`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const Check_Balance = async (requestData) => {
//   const response = await axiosInstance.post(`/Check_balance`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const OTP_AMOUNT = async (requestData) => {
//   const response = await axiosInstance.post(`/OTPVerify_amount`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }


// const Transfer_AMOUNT = async (requestData) => {
//   const response = await axiosInstance.post(`/transfer_amount`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const Transfer_History = async (requestData) => {
//   const response = await axiosInstance.post(`/transation_list`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }
// const Transfer_PayOutHistory = async (requestData) => {
//   const response = await axiosInstance.post(`/transation_payoutlist`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }
// const AgentDMTreports= async (requestData) => {
//   const response = await axiosInstance.post(`/dmt-reports`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const Recharge_Payment = async (requestData) => {
//   const response = await axiosInstance.post(`/recharge_payment`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }
// const Check_BalanceDebit = async (requestData) => {
//   const response = await axiosInstance.post(`/Check_balanceFromDebit`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const List_WithoutPage_Agent = async () => {
//   const response = await axiosInstance.get(`/without_agent_list`, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }


// const OnBoard_PaysPrint = async () => {
//   const response = await axiosInstance.get(`/onboard`,  {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const OnBoard_PaysPrintRegister = async (requestData) => {
//   const response = await axiosInstance.post(`/paysprint_registration`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const PaysPrintAuth = async (requestData) => {
//   const response = await axiosInstance.post(`/paysprint_Auth`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const agent_merchant_authencity = async (requestData) => {
//   const response = await axiosInstance.post(`/paysprint_merchant_authencity`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const AEPS_Balance_Enquery = async (requestData) => {
//   const response = await axiosInstance.post(`/balance_enquery`, requestData, {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }

// const AEPS_BankList = async () => {
//   const response = await axiosInstance.get(`/banklist`,  {
//     headers: {
//         'x-agent-apay':getTokenFromStore(),
//       }
//   });
//     return response;
// }
// const Agent_List_New = async (requestData) => {
  
//   const response = await axiosInstance.post(`/agent_list_new`, requestData, {
//     headers: {
//       'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
//       }
// });

//   return response;
// }

// const AEPS_Report = async (requestData) => {
//   const response = await axiosInstance.post(`/aepstransation_list`, requestData, {
//     headers: {
//       'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
//       }
// });
//   return response;
// }


// const Agent_Logout = async () => {
//   const response = await axiosInstance.get(`/logout`,  {
//     headers: {
//       'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
//       }
// });
//   return response;
// }

// const CreditToDebit = async (requestData) => {
//   const response = await axiosInstance.post(`/transfer_amountcredittodebit`, requestData, {
//     headers: {
//       'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
//       }
// });
//   return response;
// }

// const CheckActiveService = async (requestData) => {
//   const response = await axiosInstance.post(`/activeservice`, requestData, {
//     headers: {
//       'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
//       }
// });
//   return response;
// }

// const AgentLagerReport = async (requestData) => {
//   const response = await axiosInstance.post(`/reports`, requestData, {
//     headers: {
//       'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
//       }
// });
//   return response;
// }
// const AgentLagerCreditReport = async (requestData) => {
//   const response = await axiosInstance.post(`/reports_credit`, requestData, {
//     headers: {
//       'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
//       }
// });
//   return response;
// }

// const CheckAEPS_OnboarURL = async (requestData) => {
//   const response = await axiosInstance.post(`https://anshpe.com/callbackurl`, requestData, {
//     headers: {
//       'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
//       }
// });
//   return response;
// }

// const DashboardActiveService = async (requestData) => {
//   const response = await axiosInstance.post(`/activeserviceondashboard`, requestData, {
//     headers: {
//       'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
//       }
// });
//   return response;
// }
// const NewChangePassword = async (requestData) => {
//     const response = await axiosInstance.post(`/InnerChangePssword`, requestData, {
//       headers: {
//         'x-agent-apay': getTokenFromStore(),
//       }
//     });
    
//     return response;
 
// }

// const AgentMPINOTP = async (requestData) => {
//   const response = await axiosInstance.post(`/mpinandsendotp`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const AgentSavempin = async (requestData) => {
//   const response = await axiosInstance.post(`/verifyandsavempin`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const DMTQueryRemmiter = async (requestData) => {
//   const response = await axiosInstance.post(`/QueryRemmtter`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const RegisterRemmtter = async (requestData) => {
//   const response = await axiosInstance.post(`/RegisterRemmtter`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const AgentUPILoad = async (requestData) => {
//   const response = await axiosInstance.post(`/upiLoad`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const UPIBalanceStatus= async (requestData) => {
//   const response = await axiosInstance.post(`/upiLoadPaymentStatus`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const UPILoadReport= async (requestData) => {
//   const response = await axiosInstance.post(`/reports_upiload`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const RechargeOffer= async (requestData) => {
//   const response = await axiosInstance.post(`https://anshpe.com/admin/api/v1/rechagreoffer`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const checkOperator= async (requestData) => {
//   const response = await axiosInstance.post(`https://anshpe.com/admin/api/v1/checkoperator`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const checkreOffer= async (requestData) => {
//   const response = await axiosInstance.post(`https://anshpe.com/admin/api/v1/rechagreroffer`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }



// const AnshExpressSendOTP= async (requestData) => {
//   const response = await axiosInstance.post(`/express_userSendOTP`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const AnshExpressRegisterUser= async (requestData) => {
//   const response = await axiosInstance.post(`/express_userRegister`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }
// const AnshExpressRegisterUserFetch= async (requestData) => {
//   const response = await axiosInstance.post(`/express_userRegisterfetch`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }


// const AnshExpressBenificery= async (requestData) => {
//   const response = await axiosInstance.post(`/express_addBenificary`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const AnshExpressMoenyTransfer= async (requestData) => {
//   const response = await axiosInstance.post(`/express_money`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }
// const AnshExpressMoenyTransferConfirm= async (requestData) => {
//   const response = await axiosInstance.post(`/express_moneyConfirmdetails`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }


// const ExpressUsers= async (requestData) => {
//   const response = await axiosInstance.post(`/expressUser_list`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }
// const ExpressUserBeneificery= async (requestData) => {
//   const response = await axiosInstance.post(`/expressUser_Benificarylist`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const ExpressTransations= async (requestData) => {
//   const response = await axiosInstance.post(`/expresstransation_list`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const Agentdashboardreports= async (requestData) => {
//   const response = await axiosInstance.post(`/dashboardreports`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }

// const AgentDebitReportsWithUplines= async (requestData) => {
//   const response = await axiosInstance.post(`/debitreportswithupline`, requestData, {
//     headers: {
//       'x-agent-apay': getTokenFromStore(),
//     }
//   });
  
//   return response;

// }



// export {
//     Agent_LoginOTPService,
//     LoginVerifyAgentService,
//     LogoutandOTPService,
//     LogoutVerifyService,
//     kyc_updatebyself,
//     Add_New_Beneficiary,
//     Check_IFSC,
//     Check_Account_Verify,
//     List_Beneficiary,
//     Profile_agent_,
//     kyc_AadharFrontbyself,
//     kyc_AadharBackbyself,
//     kyc_PANbyself,
//     GoDashboard,
//     Check_UPIVerify,
//     Added_UPIVerify,
//     List_UPI,
//     Choosebeneficiary,
//     OTP_AMOUNT,
//     Transfer_AMOUNT,
//     Check_Balance,
//     Transfer_History,
//     Transfer_PayOutHistory,
//     Recharge_Payment,
//     Check_BalanceDebit,
//     List_WithoutPage_Agent,
//     OnBoard_PaysPrint,
//     OnBoard_PaysPrintRegister,
//     PaysPrintAuth,
//     agent_merchant_authencity,
//     AEPS_BankList,
//     AEPS_Balance_Enquery,
//     Agent_List_New,
//     AEPS_Report,
//     Agent_Logout,
//     CreditToDebit,
//     CheckActiveService,
//     AgentLagerReport,
//     AgentLagerCreditReport,
//     CheckAEPS_OnboarURL,
//     DashboardActiveService,
//     ForgotPassword,
//     VerifyForgotPassword,
//     NewChangePassword,AgentMPINOTP,
//     AgentSavempin,
//     DMTQueryRemmiter,
//     RegisterRemmtter,
//     AgentUPILoad,
//     UPIBalanceStatus,
//     UPILoadReport,
//     RechargeOffer,
//     checkOperator,checkreOffer,
//     Profile_agentpost,
//     AnshExpressSendOTP,
//     AnshExpressRegisterUser,
//     AnshExpressBenificery,
//     AnshExpressMoenyTransfer,
//     ExpressUsers,
//     ExpressUserBeneificery,
//     ExpressTransations,
//     Agentdashboardreports,
//     AgentPhoto,
//     AnshExpressRegisterUserFetch,
//     AgentDebitReportsWithUplines,
//     AnshExpressMoenyTransferConfirm,
//     AgentDMTreports
// }





import { AGENT_SERVICE_URL,DEFAULT_AGENTTOKEN } from './Config';

import axios from 'axios';

import store from '../store';

// URL obfuscation function
function obfuscateURL(url) {
  // Obfuscation algorithm ka istemal karein, jaise ki reverse karke
  return url.split('').reverse().join('');
}



const getTokenFromStore = () => {
  const { agent } = store.getState();
  
  return agent.agent_isToken; // Assuming 'isToken' is the token state in your 'auth' slice
};


const axiosInstance = axios.create({
  baseURL: AGENT_SERVICE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-agent-apay': DEFAULT_AGENTTOKEN,
  },
});


axiosInstance.interceptors.response.use(
  (response) => {
  
    if ( 
      // response.data.message=="This token is already expired!!" ||
       response.data.message === 'Forbidden error! You must set token type' || 
        response.data.message === 'Forbidden error! You must set token' || 
        response.data.message === 'Forbidden error!' || 
        response.data.message === 'Invalid token' || 
        response.data.message === 'Unauthorized Token!') {
        //localStorage.clear();
        //window.location.href = '/agents/login';
    }
    return response;
  },
  (error) => {

    return Promise.reject(error);
  }
);

const Agent_LoginOTPService = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/agent_login`, requestData);
    const authToken = response.headers['x-agent-apay'];
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}

const LogoutandOTPService = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/agentlogoutandcontinue`, requestData);
    const authToken = response.headers['x-agent-apay'];
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}

const LogoutVerifyService = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/AgentLogoutVerifyOtp`, requestData);
    const authToken = response.headers['x-agent-apay'];
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}


const ForgotPassword = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/agentforgotPassword`, requestData);
    const authToken = response.headers['x-agent-apay'];
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}

const VerifyForgotPassword = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/agentChangePssword`, requestData);
    const authToken = response.headers['x-agent-apay'];
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}


const LoginVerifyAgentService = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/agent_loginVerify`, requestData);
    const authToken = response.headers['x-agent-apay'];
 
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}



const kyc_updatebyself = async (requestData) => {

  const response = await axiosInstance.post(`/kyc_updatebyself`, requestData, {
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-agent-apay':getTokenFromStore(),
      }
   
});
  return response;
}

const Check_IFSC = async (requestData) => {

  const response = await axiosInstance.post(`/check_ifsc`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
    }
});
  return response;
}

const Check_Account_Verify = async (requestData) => {

  const response = await axiosInstance.post(`/check_accountverify`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
    }
});
  return response;
}

const Add_New_Beneficiary = async (requestData) => {

  const response = await axiosInstance.post(`/add_beneficiary`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
   
});
  return response;
}

const List_Beneficiary = async (requestData) => {
  const response = await axiosInstance.post(`/beneficiary_list`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}


const Profile_agent_ = async () => {
  const response = await axiosInstance.get(`/agent_profile`, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });

    return response;
}

const Profile_agentpost = async (requestData) => {
  const response = await axiosInstance.post(`/agent_profilepost`,requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const kyc_AadharFrontbyself = async (requestData) => {

  const response = await axiosInstance.post(`/upload_aadharfrontphoto`, requestData, {
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-agent-apay':getTokenFromStore(),
      }
   
});
  return response;
}

const kyc_AadharBackbyself = async (requestData) => {

  const response = await axiosInstance.post(`/upload_aadharbacktphoto`, requestData, {
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-agent-apay':getTokenFromStore(),
      }
   
});
  return response;
}

const kyc_PANbyself = async (requestData) => {

  const response = await axiosInstance.post(`/upload_panphoto`, requestData, {
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-agent-apay':getTokenFromStore(),
      }
   
});
  return response;
}
const AgentPhoto = async (requestData) => {

  const response = await axiosInstance.post(`/upload_Agentphoto`, requestData, {
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-agent-apay':getTokenFromStore(),
      }
   
});
  return response;
}


const GoDashboard = async () => {
  const response = await axiosInstance.get(`/go_dashboard`, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const Check_UPIVerify = async (requestData) => {

  const response = await axiosInstance.post(`/check_upiverify`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
    }
});
  return response;
}

const Added_UPIVerify = async (requestData) => {

  const response = await axiosInstance.post(`/added_upi`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
    }
});
  return response;
}

const List_UPI = async (requestData) => {
  const response = await axiosInstance.post(`/upi_list`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const Choosebeneficiary = async (requestData) => {
  const response = await axiosInstance.post(`/selectedbeneficiary`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const Check_Balance = async (requestData) => {
  const response = await axiosInstance.post(`/Check_balance`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const OTP_AMOUNT = async (requestData) => {
  const response = await axiosInstance.post(`/OTPVerify_amount`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}


const Transfer_AMOUNT = async (requestData) => {
  const response = await axiosInstance.post(`/transfer_amount`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const Transfer_History = async (requestData) => {
  const response = await axiosInstance.post(`/transation_list`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
  
    return response;
}
const Transfer_PayOutHistory = async (requestData) => {
  const response = await axiosInstance.post(`/transation_payoutlist`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}


const Recharge_Payment = async (requestData) => {
  const response = await axiosInstance.post(`/recharge_payment`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}
const Check_BalanceDebit = async (requestData) => {
  const response = await axiosInstance.post(`/Check_balanceFromDebit`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const List_WithoutPage_Agent = async () => {
  const response = await axiosInstance.get(`/without_agent_list`, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}


const OnBoard_PaysPrint = async () => {
  const response = await axiosInstance.get(`/onboard`,  {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const OnBoard_PaysPrintRegister = async (requestData) => {
  const response = await axiosInstance.post(`/paysprint_registration`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const PaysPrintAuth = async (requestData) => {
  const response = await axiosInstance.post(`/paysprint_Auth`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const agent_merchant_authencity = async (requestData) => {
  const response = await axiosInstance.post(`/paysprint_merchant_authencity`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const AEPS_Balance_Enquery = async (requestData) => {
  const response = await axiosInstance.post(`/balance_enquery`, requestData, {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}

const AEPS_BankList = async () => {
  const response = await axiosInstance.get(`/banklist`,  {
    headers: {
        'x-agent-apay':getTokenFromStore(),
      }
  });
    return response;
}
const Agent_List_New = async (requestData) => {
  
  const response = await axiosInstance.post(`/agent_list_new`, requestData, {
    headers: {
      'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});

  return response;
}

const AEPS_Report = async (requestData) => {
  const response = await axiosInstance.post(`/aepstransation_list`, requestData, {
    headers: {
      'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}


const Agent_Logout = async () => {
  const response = await axiosInstance.get(`/logout`,  {
    headers: {
      'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const CreditToDebit = async (requestData) => {
  const response = await axiosInstance.post(`/transfer_amountcredittodebit`, requestData, {
    headers: {
      'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const CheckActiveService = async (requestData) => {
  const response = await axiosInstance.post(`/activeservice`, requestData, {
    headers: {
      'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  // if (response.data.message === 'This token is already expired!!'){
  //   localStorage.clear();
  //   window.location.href = "/agents/login"
  // }
  return response;
}

const AgentLagerReport = async (requestData) => {
  const response = await axiosInstance.post(`/reports`, requestData, {
    headers: {
      'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}
const AgentLagerCreditReport = async (requestData) => {
  const response = await axiosInstance.post(`/reports_credit`, requestData, {
    headers: {
      'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const CheckAEPS_OnboarURL = async (requestData) => {
  const response = await axiosInstance.post(`https://anshpe.com/callbackurl`, requestData, {
    headers: {
      'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const DashboardActiveService = async (requestData) => {
  const response = await axiosInstance.post(`/activeserviceondashboard`, requestData, {
    headers: {
      'x-agent-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}
const NewChangePassword = async (requestData) => {
    const response = await axiosInstance.post(`/InnerChangePssword`, requestData, {
      headers: {
        'x-agent-apay': getTokenFromStore(),
      }
    });
    
    return response;
 
}

const AgentMPINOTP = async (requestData) => {
  const response = await axiosInstance.post(`/mpinandsendotp`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const AgentSavempin = async (requestData) => {
  const response = await axiosInstance.post(`/verifyandsavempin`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const DMTQueryRemmiter = async (requestData) => {
  const response = await axiosInstance.post(`/QueryRemmtter`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const RegisterRemmtter = async (requestData) => {
  const response = await axiosInstance.post(`/RegisterRemmtter`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}
const KYCRemmtter = async (requestData) => {
  const response = await axiosInstance.post(`/kycRemmtter`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const FetchBeneficiary = async (requestData) => {
  const response = await axiosInstance.post(`/FetchBeneficiary`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const DeleteBeneficiary= async (requestData) => {
  const response = await axiosInstance.post(`/DeleteBeneficiary`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const DMTtransactionSendOTP= async (requestData) => {
  const response = await axiosInstance.post(`/transactionSendOTP`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const DMTtransactionVerifyOTP= async (requestData) => {
  const response = await axiosInstance.post(`/DMTtransaction`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}



const RegisterBeneficiary = async (requestData) => {
  const response = await axiosInstance.post(`/RegisterBeneficiary`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}


const AgentUPILoad = async (requestData) => {
  const response = await axiosInstance.post(`/upiLoad`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const UPIBalanceStatus= async (requestData) => {
  const response = await axiosInstance.post(`/upiLoadPaymentStatus`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const UPILoadReport= async (requestData) => {
  const response = await axiosInstance.post(`/reports_upiload`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const RechargeOffer= async (requestData) => {
  const response = await axiosInstance.post(`https://anshpe.com/admin/api/v1/rechagreoffer`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const checkOperator= async (requestData) => {
  const response = await axiosInstance.post(`https://anshpe.com/admin/api/v1/checkoperator`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const checkreOffer= async (requestData) => {
  const response = await axiosInstance.post(`https://anshpe.com/admin/api/v1/rechagreroffer`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}






const AnshExpressSendOTP= async (requestData) => {
  const response = await axiosInstance.post(`/express_userSendOTP`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const AnshExpressRegisterUser= async (requestData) => {
  const response = await axiosInstance.post(`/express_userRegister`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}
const AnshExpressRegisterUserFetch= async (requestData) => {
  const response = await axiosInstance.post(`/express_userRegisterfetch`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}


const AnshExpressBenificery= async (requestData) => {
  const response = await axiosInstance.post(`/express_addBenificary`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const AnshExpressMoenyTransfer= async (requestData) => {
  const response = await axiosInstance.post(`/express_money`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}
const AnshExpressMoenyTransferConfirm= async (requestData) => {
  const response = await axiosInstance.post(`/express_moneyConfirmdetails`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}


const ExpressUsers= async (requestData) => {
  const response = await axiosInstance.post(`/expressUser_list`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}
const ExpressUserBeneificery= async (requestData) => {
  const response = await axiosInstance.post(`/expressUser_Benificarylist`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const ExpressTransations= async (requestData) => {
  const response = await axiosInstance.post(`/expresstransation_list`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const Agentdashboardreports= async (requestData) => {
  const response = await axiosInstance.post(`/dashboardreports`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}
const AgentDMTreports= async (requestData) => {
  const response = await axiosInstance.post(`/dmt-reports`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}


const AgentDebitReportsWithUplines= async (requestData) => {
  const response = await axiosInstance.post(`/debitreportswithupline`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const changedposition= async (requestData) => {
  const response = await axiosInstance.post(`/changedposition`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const reupdatelocation= async (requestData) => {
  const response = await axiosInstance.post(`/agent_reupdatelocation`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const agentfundreport= async (requestData) => {
  const response = await axiosInstance.post(`/fundwallet_report`, requestData, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  
  return response;

}

const manualbanklist= async () => {
  const response = await axiosInstance.get(`/banklistmanual`, {
    headers: {
      'x-agent-apay': getTokenFromStore(),
    }
  });
  return response;
}


export {
    Agent_LoginOTPService,
    LoginVerifyAgentService,
    LogoutandOTPService,
    LogoutVerifyService,
    kyc_updatebyself,
    Add_New_Beneficiary,
    Check_IFSC,
    Check_Account_Verify,
    List_Beneficiary,
    Profile_agent_,
    kyc_AadharFrontbyself,
    kyc_AadharBackbyself,
    kyc_PANbyself,
    GoDashboard,
    Check_UPIVerify,
    Added_UPIVerify,
    List_UPI,
    Choosebeneficiary,
    OTP_AMOUNT,
    Transfer_AMOUNT,
    Check_Balance,
    Transfer_History,
    Transfer_PayOutHistory,
    Recharge_Payment,
    Check_BalanceDebit,
    List_WithoutPage_Agent,
    OnBoard_PaysPrint,
    OnBoard_PaysPrintRegister,
    PaysPrintAuth,
    agent_merchant_authencity,
    AEPS_BankList,
    AEPS_Balance_Enquery,
    Agent_List_New,
    AEPS_Report,
    Agent_Logout,
    CreditToDebit,
    CheckActiveService,
    AgentLagerReport,
    AgentLagerCreditReport,
    CheckAEPS_OnboarURL,
    DashboardActiveService,
    ForgotPassword,
    VerifyForgotPassword,
    NewChangePassword,AgentMPINOTP,
    AgentSavempin,
    DMTQueryRemmiter,
    RegisterRemmtter,
    AgentUPILoad,
    UPIBalanceStatus,
    UPILoadReport,
    RechargeOffer,
    checkOperator,checkreOffer,
    Profile_agentpost,
    AnshExpressSendOTP,
    AnshExpressRegisterUser,
    AnshExpressBenificery,
    AnshExpressMoenyTransfer,
    ExpressUsers,
    ExpressUserBeneificery,
    ExpressTransations,
    Agentdashboardreports,
    AgentPhoto,
    AnshExpressRegisterUserFetch,
    AgentDebitReportsWithUplines,
    AnshExpressMoenyTransferConfirm,
    KYCRemmtter,
    RegisterBeneficiary,
    FetchBeneficiary,
    DeleteBeneficiary,
    DMTtransactionSendOTP,
    DMTtransactionVerifyOTP,
    AgentDMTreports,
    changedposition,
    reupdatelocation,
    agentfundreport,
    manualbanklist
}
