import React from 'react';

const AdminLayout = React.lazy(() => import('../modules/admin/AdminLayout'));
const UserLayout = React.lazy(() => import('../modules/website/UserLayout'));
const AgentLayout = React.lazy(() => import('../modules/agents/AgentsLayout'));


const LazyLogin = React.lazy(() => import('../modules/login/Login'));
const LazyAgentsLogin = React.lazy(() => import('../modules/AgentLogin/Login'));


//Website
const LazyHome = React.lazy(() => import('../modules/website/pages/Home'));

//Admin Pages

const LazyDashboard = React.lazy(() => import('../modules/admin/pages/Dashboard'));
const LazyAddMember = React.lazy(() => import('../modules/admin/pages/Addmember'));
const LazyExistAddMember = React.lazy(() => import('../modules/admin/pages/ExistAddMember'));
const LazyAgentsList = React.lazy(() => import('../modules/admin/pages/AgentsList'));
const LazyCreatecommisonslot = React.lazy(() => import('../modules/admin/pages/CreateCommisonSlot'));
const LazyAnshServices = React.lazy(() => import('../modules/admin/pages/AnshServices'));
const LazyMainSlotList = React.lazy(() => import('../modules/admin/pages/MainSlotList'));
const LazyUserpackege = React.lazy(() => import('../modules/admin/pages/UserPackege'));
const LazyPackageList = React.lazy(() => import('../modules/admin/pages/PackageList'));
const LazyPackageView = React.lazy(() => import('../modules/admin/pages/PackageView'));
const LazyAgentProfile= React.lazy(() => import('../modules/admin/pages/AgentProfile'));
const LazyCreditReportAdmin=React.lazy(() => import('../modules/admin/pages/CreditReportAdmin'));
const LazyDebitReportAdmin=React.lazy(() => import('../modules/admin/pages/DebitReport'));
const LazyAdminUPIReport=React.lazy(() => import('../modules/admin/pages/AdminUPILoadReport'));
const LazyAdminPayoutReport=React.lazy(() => import('../modules/admin/pages/AdminPayoutReport'));
const LazyAdminRechargeReport=React.lazy(() => import('../modules/admin/pages/RechargeReport'));
const LazyExpressReports=React.lazy(() => import('../modules/admin/pages/ExpressReports'));
const LazyAgentCreditReport=React.lazy(() => import('../modules/admin/pages/AgentCreditReport'));
const LazyAgentDebitReport=React.lazy(() => import('../modules/admin/pages/AgentDebitReport'));
const LazyConvertToOtherPost=React.lazy(() => import('../modules/admin/pages/ConvertToOtherPost'));
const LazyUPIALL=React.lazy(() => import('../modules/admin/pages/AdminUPIList'));
const LazyBeneficiaryALL=React.lazy(() => import('../modules/admin/pages/AdminBeneficiary'));
const LazyAEPSReports=React.lazy(() => import('../modules/admin/pages/AEPSReports'));
const LazyTotalLedgerReport=React.lazy(() => import('../modules/admin/pages/TotalLedgerReport'));
const LazyTotalDebitLedger=React.lazy(() => import('../modules/admin/pages/TotalDebitLedger'));
const LazyAdminFundTransferReport=React.lazy(() => import('../modules/admin/pages/AdminFundTransferReport'));
const LazyWalletTopUp= React.lazy(() => import('../modules/admin/pages/WalletTopUp'));
const LazyDMTReports=React.lazy(() => import('../modules/admin/pages/DMTReports'));
const LazyAnshBankList = React.lazy(() => import('../modules/admin/pages/AnshBankList'));
//Agets Pages

const LazyAgentDashboard = React.lazy(() => import('../modules/agents/pages/Dashboard'));
const LazyOthersubbmiData = React.lazy(() => import('../modules/agents/pages/Othersubbmitdata'));
const LazyAllService = React.lazy(() => import('../modules/agents/pages/AllServices'));
const LazyServiceDetails = React.lazy(() => import('../modules/agents/pages/ServiceDetails'));
const LazyPayout = React.lazy(() => import('../modules/agents/pages/Payout'));
const LazyBeneficiary = React.lazy(() => import('../modules/agents/pages/Beneficiary'));
const LazyUPI = React.lazy(() => import('../modules/agents/pages/Upi'));
const LazyProfileDetails = React.lazy(() => import('../modules/agents/pages/ProfileDetails'));
const LazyOnboard = React.lazy(() => import('../modules/agents/pages/Onboard'));
// const LazyAepsService= React.lazy(() => import('../modules/agents/pages/AepsService'));
const LazyDebitReport=React.lazy(() => import('../modules/agents/pages/DebitReport'));
const LazyCreditReport=React.lazy(() => import('../modules/agents/pages/CreditReport'));
const LazyUPIReport=React.lazy(() => import('../modules/agents/pages/UPILoadReport'));
const LazyAgentProfiles= React.lazy(() => import('../modules/agents/pages/AgentProfile'));
const LazyAddAgent = React.lazy(() => import('../modules/agents/pages/Addmember'));
const LazyAgentsListMember = React.lazy(() => import('../modules/agents/pages/AgentList'));
const LazyAEPSCallback = React.lazy(() => import('../modules/agents/pages/AEPSCallback'));
const LazyDTHRecharge = React.lazy(() => import('../modules/agents/pages/DTHRecharge'));
const LazyChangePassword = React.lazy(() => import('../modules/agents/pages/ChangePassword'));

const LazyDMTServices= React.lazy(() => import('../modules/agents/pages/DMTServices'));
const LazyExpressUsers= React.lazy(() => import('../modules/agents/pages/ExpressUsers'));
const LazyExpressPayout= React.lazy(() => import('../modules/agents/pages/ExpressPayout'));

const LazyReceipt= React.lazy(() => import('../modules/agents/pages/Receipt'));
const LazyDebitReports= React.lazy(() => import('../modules/agents/pages/DebitReports'));


const LazyMobileReport=React.lazy(() => import('../modules/agents/pages/MobileReports'));
const LazyAGENTDMTReports=React.lazy(() => import('../modules/agents/pages/DMTReports'));
const LazyAgentPayoutReport= React.lazy(() => import('../modules/agents/pages/AgentPayoutReport'));
const LazyAGENTExpressReport=React.lazy(() => import('../modules/agents/pages/ExpressReport'));
const LazyAGENTFundReport=React.lazy(() => import('../modules/agents/pages/FundReport'));
const LazyAEPSBank3= React.lazy(() => import('../modules/agents/pages/AEPSBank3'));
const LazyAepsService= React.lazy(() => import('../modules/agents/pages/NewAEPSService'));

const Menuitem =  [
  {
    name: 'Home',
    path: '/',
    layout: UserLayout,
    component: LazyHome,
    children: [],
    isProtected: 0,
    allowedRoles: [],
  },
  {
    name: 'Login',
    path: '/admin/login',
    layout: LazyLogin,
    isProtected: 0,
    children: [],
    allowedRoles: [],
  },
    {
      name: 'Login',
      path: '/agents/login',
      layout: LazyAgentsLogin,
      isProtected: 0,
      children: [],
      allowedRoles: [],
    },
  
    { 
      name: 'Dashboard',
      path: '/admin/dashboard',
      layout: AdminLayout,
      component: LazyDashboard,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    
    { 
      name: 'Add Member',
      path: '/admin/addmember',
      layout: AdminLayout,
      component: LazyAddMember,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },

    { 
      name: 'Agent Credit Ledger Report',
      path: '/admin/agent_credit/:id',
      layout: AdminLayout,
      component: LazyAgentCreditReport,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },

    { 
      name: 'Agent Debit Ledger Report',
      path: '/admin/agent_debit/:id',
      layout: AdminLayout,
      component: LazyAgentDebitReport,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    
    { 
      name: 'Admin Credit Ledger Reports',
      path: '/admin/reports',
      layout: AdminLayout,
      component: LazyTotalLedgerReport,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },

    { 
      name: 'Admin Debit Ledger Reports',
      path: '/admin/d-reports',
      layout: AdminLayout,
      component: LazyTotalDebitLedger,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },

    { 
      name: 'Fund Transfer Reports',
      path: '/admin/fund-reports',
      layout: AdminLayout,
      component: LazyAdminFundTransferReport,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'Wallet Top Up',
      path: '/admin/wallet-topup',
      layout: AdminLayout,
      component: LazyWalletTopUp,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    
    { 
      name: 'IFSC Code Management',
      path: '/admin/bank_listservices',
      layout: AdminLayout,
      component: LazyAnshBankList,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'Exist Add Member',
      path: '/admin/existaddmember',
      layout: AdminLayout,
      component: LazyExistAddMember,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'Member List',
      path: '/admin/agents',
      layout: AdminLayout,
      component: LazyAgentsList,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'Create Slot',
      path: '/admin/create_slot',
      layout: AdminLayout,
      component: LazyCreatecommisonslot,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    
    { 
      name: 'Services',
      path: '/admin/add_services',
      layout: AdminLayout,
      component: LazyAnshServices,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },

 { 
      name: 'Create Slot List',
      path: '/admin/slot_list',
      layout: AdminLayout,
      component: LazyMainSlotList,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'UPI List',
      path: '/admin/upi_list',
      layout: AdminLayout,
      component: LazyUPIALL,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'Beneficiary List',
      path: '/admin/beneficiary_list',
      layout: AdminLayout,
      component: LazyBeneficiaryALL,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'AEPS Reports',
      path: '/admin/aeps_reports',
      layout: AdminLayout,
      component: LazyAEPSReports,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    
    
    { 
      name: 'Create Package',
      path: '/admin/user_package',
      layout: AdminLayout,
      component: LazyUserpackege,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'Package List',
      path: '/admin/list_package',
      layout: AdminLayout,
      component: LazyPackageList,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'Credit Leger',
      path: '/admin/credit-report',
      layout: AdminLayout,
      component: LazyCreditReportAdmin,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'Debit Leger',
      path: '/admin/debit-report',
      layout: AdminLayout,
      component: LazyDebitReportAdmin,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    { 
      name: 'Convert To Other Post',
      path: '/admin/converter',
      layout: AdminLayout,
      component: LazyConvertToOtherPost,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },

    { 
      name: 'UPI Report',
      path: '/admin/upiload-report',
      layout: AdminLayout,
      component: LazyAdminUPIReport,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin']

    },
    { 
      name: 'Payout Report',
      path: '/admin/payout-report',
      layout: AdminLayout,
      component:LazyAdminPayoutReport,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin']

    },
    { 
      name: 'Express Report',
      path: '/admin/express-report',
      layout: AdminLayout,
      component:LazyExpressReports,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin']

    },
    { 
      name: 'DMT Report',
      path: '/admin/dmt-report',
      layout: AdminLayout,
      component:LazyDMTReports,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin']

    },
    
    { 
      name: 'Recharge Report',
      path: '/admin/recharge-report',
      layout: AdminLayout,
      component:LazyAdminRechargeReport,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin']

    },
    
    { 
      name: 'Package Details',
      path: '/admin/package_details/:id',
      layout: AdminLayout,
      component: LazyPackageView,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },

    { 
      name: 'Member Details',
      path: '/admin/agent_details/:id',
      layout: AdminLayout,
      component: LazyAgentProfile,
      children: [],
      isProtected: 1,
      allowedRoles: ['Superadmin'],
    },
    
    { 
      name: 'Dashboard',
      path: '/agents/dashboard',
      layout: AgentLayout,
      component: LazyAgentDashboard,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
   
    { 
      name: 'KYC Update',
      path: '/agents/kyc_update',
      layout: AgentLayout,
      component: LazyOthersubbmiData,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:0,
    },
    // { 
    //   name: 'KYC Profile',
    //   path: '/agents/kyc_profile',
    //   layout: AgentLayout,
    //   component: LazyProfileDetails,
    //   children: [],
    //   isProtected: 2,
    //   allowedRoles: ['agents'],
    //   isKyc:0,
    // },

    { 
      name: 'Active Service',
      path: '/agents/all_service',
      layout: AgentLayout,
      component: LazyAllService,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Recharge Service',
      path: '/agents/service_recharge',
      layout: AgentLayout,
      component: LazyServiceDetails,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Payout Service',
      path: '/agents/service_payout',
      layout: AgentLayout,
      component: LazyPayout,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    
    { 
      name: 'Receipt',
      path: '/agents/receipt',
      layout: AgentLayout,
      component: LazyReceipt,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    
    { 
      name: 'Beneficiary',
      path: '/agents/beneficiary',
      layout: AgentLayout,
      component: LazyBeneficiary,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Express Users',
      path: '/agents/express-users',
      layout: AgentLayout,
      component: LazyExpressUsers,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Express Money Transfer',
      path: '/agents/express-transfer',
      layout: AgentLayout,
      component: LazyExpressPayout,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    
    { 
      name: 'UPI',
      path: '/agents/upi',
      layout: AgentLayout,
      component: LazyUPI,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
   
    { 
      name: 'Profile',
      path: '/agents/profile',
      layout: AgentLayout,
      component: LazyProfileDetails,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    
    { 
      name: 'Add Member',
      path: '/agents/addmember',
      layout: AgentLayout,
      component: LazyAddAgent,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Member List',
      path: '/agents/agents_list',
      layout: AgentLayout,
      component: LazyAgentsListMember,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
    },
    { 
      name: 'DMT Service',
      path: '/agents/dmt-service',
      layout: AgentLayout,
      component: LazyDMTServices,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
    },
    
    { 
      name: 'Onboard',
      path: '/agents/member-onboard',
      layout: AgentLayout,
      component: LazyOnboard,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'AEPS Service',
      path: '/agents/aeps-service',
      layout: AgentLayout,
      component: LazyAepsService,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Debit Report',
      path: '/agents/debitwallet-report',
      layout: AgentLayout,
      component: LazyDebitReport,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },

    { 
      name: 'Debit Report',
      path: '/agents/debit-report',
      layout: AgentLayout,
      component: LazyDebitReports,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    
    { 
      name: 'Credit Report',
      path: '/agents/creditwallet-report',
      layout: AgentLayout,
      component: LazyCreditReport,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'UPI Load Reports',
      path: '/agents/upiload-report',
      layout: AgentLayout,
      component: LazyUPIReport,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Fund Reports',
      path: '/agents/fund-report',
      layout: AgentLayout,
      component: LazyAGENTFundReport,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    
    { 
      name: 'Callback Data',
      path: '/agents/callback',
      layout: AgentLayout,
      component: LazyAEPSCallback,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Change Password',
      path: '/agents/change-password',
      layout: AgentLayout,
      component: LazyChangePassword,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Member Details',
      path: '/agents/agent_details/:id',
      layout: AgentLayout,
      component: LazyAgentProfiles,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'DTH Service',
      path: '/agents/dth_recharge',
      layout: AgentLayout,
      component: LazyDTHRecharge,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Mobile Recharge Reports',
      path: '/agents/mobile-report',
      layout: AgentLayout,
      component: LazyMobileReport,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'DMT Reports',
      path: '/agents/dmt-report',
      layout: AgentLayout,
      component: LazyAGENTDMTReports,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    
    { 
      name: 'Payout Service',
      path: '/agents/payout-report',
      layout: AgentLayout,
      component: LazyAgentPayoutReport,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'Express Reports',
      path: '/agents/express-report',
      layout: AgentLayout,
      component: LazyAGENTExpressReport,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    { 
      name: 'AEPS Service',
      path: '/agents/aeps-service-bank3',
      layout: AgentLayout,
      component: LazyAEPSBank3,
      children: [],
      isProtected: 2,
      allowedRoles: ['agents'],
      isKyc:1,
    },
    
    
  ];
  
  export default Menuitem;
